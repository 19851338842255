import * as L from "leaflet";
import iconVermelho from '../images/map-point-svgrepo-com.svg'

const LeafIcon = L.Icon.extend({
    options: {}
});


const novaOcorrencia = new LeafIcon({
    iconUrl:
        "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: new L.Point(60, 75),
})

const antigaOcorrencia = new LeafIcon({
    iconUrl:
        "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: new L.Point(60, 75),
})


export default { novaOcorrencia, antigaOcorrencia };