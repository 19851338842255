const { useState, useEffect } = require("react")

const CorSemaforo = status => {
    const [cor, setCor] = useState(null)

    const obterTrocaCores = status => {
        if (status && !cor)
            return "Verde"
        if (status && cor === "Verde")
            return "Amarelo"
        if (status && cor === "Amarelo")
            return "Vermelho"
        if (status && cor === "Vermelho")
            return "Verde"
    }

    const obterColor = cor => {
        if (cor === "Verde")
            return "green"
        if (cor === "Amarelo")
            return "#c4c407"
        if (cor === "Vermelho")
            return "red"
    }

    useEffect(() => {
        if (status) setCor(obterTrocaCores(status))
    }, [status])

    return (
        <>
            <h6>Cor do semáforo
                <span
                    style={{ 'color': obterColor(cor) }}>
                    {cor === null ? " desligado" : ` ${cor}`}</span>
            </h6>
        </>
    )

}

export default CorSemaforo
