import './mapa.css';
import { useState, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import iconOcorrencia from './partials/icones-personalizados'
import ExecutaFlyto from './Mapa-flyto';
import { useSearchParams } from 'react-router-dom';


const LocationMarker = ({ pos }) => {
    return pos === null ? null : (
        <Marker
            key={pos.id}
            position={[pos.lat, pos.lon]}
            icon={pos.status === "Identificado" ? iconOcorrencia.novaOcorrencia : iconOcorrencia.antigaOcorrencia}
            attribution={`${pos.status === "Identificado" ? "old" : "new"}`}>
            <Popup>
                <p>Nível de criticidade: {pos.critidade_nivel}</p>
                <p>Estação: {pos.estacao}</p>
                <p>Fonte: {pos.fonte}</p>
            </Popup>
        </Marker>
    )
}

const MapaBody = ({
    dados,
    status,
    totalOcorrencia,
    ocorrencias,
    identificador }) => {

    const [position, setPosition] = useState(null)
    const [incrementador, setIncrementador] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [requiredid, setRequiredid] = useState('1234')

    const map = useMap()

    useEffect(() => {
        const req = searchParams.get('requestid')
        if (req) setRequiredid(req)

        console.log('incrementador ', incrementador, 'totalOcorrencia ', totalOcorrencia)
        if (incrementador === totalOcorrencia + 1) {
            setIncrementador(1)
        } else if (status && ocorrencias && incrementador) {
            map.whenReady(() => {
                setPosition(ocorrencias[incrementador - 1])
                setIncrementador(incrementador + 1)
            });
        } else if (incrementador === null && status && ocorrencias) {
            setIncrementador(1)
        }
    }, [ocorrencias, status])

    useEffect(() => {
        if (position) {
            // ENVIANDO OS DADOS AO SERVIDOR SATÉLITE
            // const requestid = requiredid
            const pontomapaid = position.identificador
            const latitude = position.lat
            const longitude = position.lon
            const camera1 = position.camera_1
            const camera2 = position.camera_2
            const camera3 = position.camera_3

            const get = `requestid=${requiredid}&pontomapaid=${pontomapaid}&latitude=${latitude}&longitude=${longitude}&camera_1=${camera1}&camera_2=${camera2}&camera_3=${camera3}`
            const url = `http://64.225.5.56:8000/api_camera/set?${get}`
            // const url = "http://aplicativo.cocr.com.br/api_mensageria"
            fetch(url)
                .then(r => r.json())
                .then(r => {
                    console.log(r)
                    map.flyTo([position.lat, position.lon], 18)
                    setPosition(null)
                })
        }
    }, [position, map])

    return (
        <>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {dados === null ? null : dados.map(v =>
                <LocationMarker key={v.identificador} pos={v} />
            )}
        </>
    )
}


function Mapa({ status }) {
    const [identificador, setIdentificador] = useState(null)
    const [dados, setDados] = useState(null)
    const [ocorrencias, setOcorrencias] = useState(null)
    const [totalOcorrencia, setTotalOcorrencia] = useState(null)

    useEffect(() => {
        const _inteiro_aleatorio = Math.random() * 1000000
        if (identificador === null) setIdentificador(_inteiro_aleatorio)
        // const url = "http://64.225.5.56:8000/api_mensageria"
        const url = "http://aplicativo.cocr.com.br/api_mensageria"
        fetch(url)
            .then(r => r.json())
            .then(r => {
                const ocorrencias = r.estacoes.filter(r => r.status === "Identificado")
                setDados(r.estacoes)
                setOcorrencias(ocorrencias)
                setTotalOcorrencia(ocorrencias.length)
                console.log(ocorrencias)
            })
    }, [])

    return (
        <>
            <MapContainer
                center={{ lat: -22.9332614, lng: -43.4324536 }}
                zoom={13}
                scrollWheelZoom={false}
                className='mapa'>
                <MapaBody
                    dados={dados}
                    ocorrencias={ocorrencias}
                    status={status}
                    totalOcorrencia={totalOcorrencia}
                    identificador={identificador} />
            </MapContainer>,
        </>
    )
}


export default Mapa;
