import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import './App.css';
import BotaoGeral from './semaforo/botao-geral'


function App() {
  return (
    <>
      <Container fluid>
        <Row className='mb-3'>
          <Col>
            <BotaoGeral />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
