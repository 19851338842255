import { convertLength } from '@mui/material/styles/cssUtils';
import './mapa.css';
import { useState, useEffect } from 'react'
import { useMap } from 'react-leaflet'


const FlyTo = ({ pos }) => {
    const [posicao, setPosicao] = useState(null)

    const map = useMap(null)

    useEffect(() => {
        map.whenReady(() => {
            console.log('aqui', posicao)
            map.flyTo(posicao, 18)
        })
    }, [posicao])

    if (!posicao) {
        setPosicao(pos)
    }

    return null
}


function ExecutaFlyto() {
    const [dados, setDados] = useState(null)

    useEffect(() => {
        fetch("http://localhost:9003/teste_api_mensageria_2/")
            .then(r => r.json())
            .then(setDados)
    }, [])

    if (dados) {
        return <FlyTo key={dados[0].id} pos={dados[0].latlon} />
    }

}


export default ExecutaFlyto;
