import { useEffect, useState } from "react"
import GerenciadorStatus from "./gerenciador-status"


const BotaoGeral = () => {
    const [status, setStatus] = useState(true)

    useEffect(() => {
        // setStatus(true)
    }, [])

    useEffect(() => {
        const execUma = setTimeout(() => {
            if (status)
                console.log('status ativado')
            else
                console.log('status desativado')
        }, 300);

        return () => clearTimeout(execUma)
    }, [status])

    return (
        <>
            {/* <h4>Status Semáforo
                <span>
                    {status === true ? ' Ativado' : ' Desativado'}
                </span>
            </h4> */}
            <button style={{ 'margin': '12px', 'display': 'none' }} onClick={() => setStatus(!status)}>{status === true ? ' Desativar' : ' Ativar'} Flutuação</button>
            <GerenciadorStatus status_controlador={status} />
        </>
    )
}

export default BotaoGeral
