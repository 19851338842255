import { useEffect, useState } from "react"
import CorSemaforo from "./cor"
import Mapa from "../mapa/Mapa-montado"

const GerenciadorStatus = ({ status_controlador }) => {
    const [statusCor, setStatusCor] = useState(null)
    const [statusCorAuxiliar, setStatusCorAuxiliar] = useState(false)
    const [trocarCor, setTrocarCor] = useState(false)

    const SECONDS_MS = 7500

    useEffect(() => {
        setStatusCor(status_controlador)
    }, [status_controlador])

    useEffect(() => {

        const execUma = setTimeout(() => {
            // console.log('useeffect_test', 'status_controlador ', status_controlador, 'statusCor ', statusCor, 'statusCorAuxiliar ', statusCorAuxiliar)
            setTrocarCor(!trocarCor)
            if (status_controlador && statusCor && !statusCorAuxiliar)
                setStatusCorAuxiliar(true)
            if (status_controlador && !statusCor && statusCorAuxiliar)
                setStatusCorAuxiliar(!statusCorAuxiliar)
        }, SECONDS_MS)

        return () => clearTimeout(execUma)

    }, [statusCor])

    useEffect(() => {

        const execUma = setTimeout(() => {
            // console.log('useeffect_aux', 'status_controlador ', status_controlador, 'statusCor ', statusCor, 'statusCorAuxiliar ', statusCorAuxiliar)
            setTrocarCor(!trocarCor)
            if (status_controlador && statusCor && statusCorAuxiliar)
                setStatusCor(!statusCor)
            if (status_controlador && !statusCor && !statusCorAuxiliar)
                setStatusCor(!statusCor)
        }, SECONDS_MS)

        return () => clearTimeout(execUma)

    }, [statusCorAuxiliar])

    useEffect(() => {
        if (status_controlador) {
            // console.log('trocarCor ', trocarCor)
            // TODO: executar flyto
        } else {
            setStatusCor(false)
            setStatusCorAuxiliar(false)
            // TODO: center map
        }
    }, [trocarCor])

    return (
        <>
            {/* <CorSemaforo status={trocarCor} /> */}
            <Mapa status={trocarCor} />
        </>
    )
}

export default GerenciadorStatus
