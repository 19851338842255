import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Iframe from 'react-iframe'


import './App.css';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';


function Camera() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [camera, setCamera] = useState(null)
    const [requiredid, setRequiredid] = useState('1234')
    const [urlCam, setUrlCam] = useState(null)

    const MINUTE_MS = 2000;

    useEffect(() => {
        const interval = setInterval(() => {

            const cam = searchParams.get('camera')
            if (cam) setCamera(cam)
            // const req = searchParams.get('requestid')
            // if (req) setRequiredid(req)
            console.log(cam)

            if (camera !== null) {
                const get = `requestid=${requiredid}&camera=${camera}`
                const url = `http://64.225.5.56:8000/api_camera/get?${get}`
                fetch(url)
                    .then(r => r.json())
                    .then(r => {
                        if (urlCam !== r.url)
                            setUrlCam(r.url)
                    })
            }

        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [requiredid, camera])

    useEffect(() => {


    }, [])

    return urlCam === null ? null : (
        <>
            <Container fluid>
                <Row className='mb-3'>
                    <Col>
                        <Iframe url={urlCam}
                            width="800px"
                            height="600px"
                            id=""
                            className=""
                            display="block"
                            position="relative" />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Camera;
